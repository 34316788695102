export default {
  headers: [
    {
      id: 'mainHeader',
      logo: {
        alt: 'jwteam logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/vis/logo.png',
        url: 'https://www.jwteam.com/',
      },
      breakpoint: 1400,
      desktopMenu: {
        type: 'default',
        disableOnHoverMenuExpand: false,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-vis-desktop-header-logo-container',
          imageClassName: 'on-vis-desktop-header-logo',
        },
        headerClassName: 'on-vis-desktop-header',
        headerSectionsClassName: 'on-vis-desktop-header-sections',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '' || null,
        itemClassName: 'on-vis-desktop-item',
        menuClassName: 'on-vis-desktop-menu',
        menuLabelActiveClassName: 'on-vis-active-label',
        menuItemClassName: 'on-vis-desktop-item-folder',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'vis-home-item',
            url: 'https://www.jwteam.com/',
            content: 'HOME',
          },
          {
            id: 'vis-buy-item',
            content: 'BUY',
            menuItems: [
              {
                id: 'vis-buy-item-one',
                content: 'All Listings',
                url: 'https://www.jwteam.com/listing',
              },
              {
                id: 'vis-buy-item-two',
                content: 'Sold Listings',
                url: 'https://www.jwteam.com/sold-listing',
              },
              {
                id: 'vis-buy-item-three',
                content: 'Featured Listings',
                url: 'https://www.jwteam.com/featured-listing',
              },
            ],
          },
          {
            id: 'vis-sell-item',
            content: 'SELL',
            url: 'http://jwteamsold.com/',
          },
          {
            id: 'vis-home-evaluation-item',
            content: 'HOME EVALUATION',
            url: 'https://www.jwteam.com/evaluation',
          },
          {
            id: 'vis-listing-process-item',
            url: 'https://www.jwteam.com/sell',
            content: 'LISTING PROCESS',
          },
          {
            id: 'vis-exclusive-trade-item',
            url: 'https://trade-in.jwteam.com/',
            content: 'EXCLUSIVE TRADE-IN PROGRAM',
          },
          {
            id: 'vis-exclusive-cash-item',
            url: 'https://cashoffer.jwteam.com/',
            content: 'EXCLUSIVE CASH OFFER PROGRAM',
          },
          {
            id: 'vis-neighborhoods-item',
            content: 'NEIGHBORHOODS',
            menuItems: [
              {
                id: 'vis-neighborhoods-item-one',
                content: 'Austin',
                url: 'https://www.jwteam.com/neighborhoods/153766763/Austin',
              },
              {
                id: 'vis-neighborhoods-item-two',
                content: 'Buda',
                url: 'https://www.jwteam.com/neighborhoods/153766764/Buda',
              },
              {
                id: 'vis-neighborhoods-item-three',
                content: 'Cedar Park',
                url: 'https://www.jwteam.com/neighborhoods/153766765/Cedar-Park',
              },
              {
                id: 'vis-neighborhoods-item-four',
                content: 'Dripping Springs',
                url: 'https://www.jwteam.com/neighborhoods/153766766/Dripping-Springs',
              },
              {
                id: 'vis-neighborhoods-item-five',
                content: 'Georgetown',
                url: 'https://www.jwteam.com/neighborhoods/153766767/Georgetown',
              },
              {
                id: 'vis-neighborhoods-item-six',
                content: 'Hutto',
                url: 'https://www.jwteam.com/neighborhoods/153766768/Hutto',
              },
              {
                id: 'vis-neighborhoods-item-seven',
                content: 'Kyle',
                url: 'https://www.jwteam.com/neighborhoods/153766769/Kyle',
              },
              {
                id: 'vis-neighborhoods-item-eight',
                content: 'Leander',
                url: 'https://www.jwteam.com/neighborhoods/153766770/Leander',
              },
              {
                id: 'vis-neighborhoods-item-nine',
                content: 'Manchaca',
                url: 'https://www.jwteam.com/neighborhoods/153766771/Manchaca',
              },
              {
                id: 'vis-neighborhoods-item-ten',
                content: 'Pflugerville',
                url: 'https://www.jwteam.com/neighborhoods/153766772/Pflugerville',
              },
              {
                id: 'vis-neighborhoods-item-eleven',
                content: 'Round Rock',
                url: 'https://www.jwteam.com/neighborhoods/153766773/Round-Rock',
              },
              {
                id: 'vis-neighborhoods-item-twelve',
                content: 'San Marcos',
                url: 'https://www.jwteam.com/neighborhoods/153766774/San-Marcos',
              },
            ],
          },
          {
            id: 'vis-blog-item',
            url: 'https://www.jwteam.com/blog',
            content: 'BLOG',
          },
          {
            id: 'vis-about-item',
            content: 'ABOUT',
            menuItems: [
              {
                id: 'sub-three-item-one',
                content: 'About Us',
                url: 'https://www.jwteam.com/about',
              },
              {
                id: 'sub-three-item-two',
                content: 'Reviews',
                url: 'https://www.jwteam.com/reviews',
              },
              {
                id: 'sub-three-item-three',
                content: 'Careers',
                url: 'http://metroaustincareers.com/',
              },
            ],
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: 'on-vis-mheader-logo-container',
          imageClassName: 'on-vis-mheader-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: 'on-vis-hamburger',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-vis-mheader',
        headerSectionsClassName: 'on-vis-mheader-section',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-vis-mheader-menu',
        itemClassName: 'on-vis-mheader-item',
        menuClassName: 'on-vis-mheader-folder',
        menuLabelActiveClassName: 'on-vis-mheader-label-active',
        menuItemClassName: 'on-vis-mheader-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'on-vis-header-home-item',
            content: 'HOME',
            url: 'https://www.jwteam.com/',
          },
          {
            id: 'on-vis-header-about-item',
            content: 'BUY',
            menuItems: [
              {
                id: 'on-vis-header-about-item-all',
                content: 'All Listings',
                url: 'https://www.jwteam.com/listing',
              },
              {
                id: 'on-vis-header-about-item-sold',
                content: 'Sold Listings',
                url: 'https://www.jwteam.com/sold-listing',
              },
              {
                id: 'on-vis-header-about-item-featured',
                content: 'Featured Listings',
                url: 'https://www.jwteam.com/featured-listing',
              },
            ],
          },
          {
            id: 'on-vis-header-sell-item',
            content: 'SELL',
            url: 'http://jwteamsold.com/',
          },
          {
            id: 'on-vis-header-evaluation-item',
            content: 'HOME EVALUATION',
            url: 'https://www.jwteam.com/evaluation',
          },
          {
            id: 'on-vis-header-listing-item',
            url: 'https://www.jwteam.com/sell',
            content: 'LISTING PROCESS',
          },
          {
            id: 'on-vis-header-exclusive-trade-item',
            url: 'https://trade-in.jwteam.com/',
            content: 'EXCLUSIVE TRADE-IN PROGRAM',
          },
          {
            id: 'on-vis-header-exclusive-cash-item',
            url: 'https://cashoffer.jwteam.com/',
            content: 'EXCLUSIVE CASH OFFER PROGRAM',
          },
          {
            id: 'on-vis-header-neighborhoods-item',
            content: 'NEIGHBORHOODS',
            menuItems: [
              {
                id: 'vis-neighborhoods-item-one',
                content: 'Austin',
                url: 'https://www.jwteam.com/neighborhoods/153766763/Austin',
              },
              {
                id: 'vis-neighborhoods-item-two',
                content: 'Buda',
                url: 'https://www.jwteam.com/neighborhoods/153766764/Buda',
              },
              {
                id: 'vis-neighborhoods-item-three',
                content: 'Cedar Park',
                url: 'https://www.jwteam.com/neighborhoods/153766765/Cedar-Park',
              },
              {
                id: 'vis-neighborhoods-item-four',
                content: 'Dripping Springs',
                url: 'https://www.jwteam.com/neighborhoods/153766766/Dripping-Springs',
              },
              {
                id: 'vis-neighborhoods-item-five',
                content: 'Georgetown',
                url: 'https://www.jwteam.com/neighborhoods/153766767/Georgetown',
              },
              {
                id: 'vis-neighborhoods-item-six',
                content: 'Hutto',
                url: 'https://www.jwteam.com/neighborhoods/153766768/Hutto',
              },
              {
                id: 'vis-neighborhoods-item-seven',
                content: 'Kyle',
                url: 'https://www.jwteam.com/neighborhoods/153766769/Kyle',
              },
              {
                id: 'vis-neighborhoods-item-eight',
                content: 'Leander',
                url: 'https://www.jwteam.com/neighborhoods/153766770/Leander',
              },
              {
                id: 'vis-neighborhoods-item-nine',
                content: 'Manchaca',
                url: 'https://www.jwteam.com/neighborhoods/153766771/Manchaca',
              },
              {
                id: 'vis-neighborhoods-item-ten',
                content: 'Pflugerville',
                url: 'https://www.jwteam.com/neighborhoods/153766772/Pflugerville',
              },
              {
                id: 'vis-neighborhoods-item-eleven',
                content: 'Round Rock',
                url: 'https://www.jwteam.com/neighborhoods/153766773/Round-Rock',
              },
              {
                id: 'vis-neighborhoods-item-twelve',
                content: 'San Marcos',
                url: 'https://www.jwteam.com/neighborhoods/153766774/San-Marcos',
              },
            ],
          },
          {
            id: 'on-vis-header-blog-item',
            url: 'https://www.jwteam.com/blog',
            content: 'BLOG',
          },
          {
            id: 'on-vis-header-about-item',
            content: 'ABOUT',
            menuItems: [
              {
                id: 'sub-three-item-one',
                content: 'About Us',
                url: 'https://www.jwteam.com/about',
              },
              {
                id: 'sub-three-item-two',
                content: 'Reviews',
                url: 'https://www.jwteam.com/reviews',
              },
              {
                id: 'sub-three-item-three',
                content: 'Careers',
                url: 'http://metroaustincareers.com/',
              },
            ],
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-vis-footer-first',
      display: true,
      className: 'on-vis-footer-section on-vis-footer-section-first',
      sectionClassName: 'on-vis-footer-section-container',
      columns: [
        {
          id: 'footer-disclaimer',
          className: 'align-center on-vis-footer-disclaimer',
          style: {},
          items: [
            {
              id: 'footer-text-one',
              type: 'heading',
              className: 'on-vis-footer-text pt-3 pb-2',
              elementType: 'p',
              style: {},
              data: 'Based on information from the Austin Board of REALTORS® (alternatively, from ACTRIS) for the period through 2022.®All information provided is deemed reliable but is not guaranteed and should be independently verified. The Austin Board of REALTORS®, ACTRIS and their affiliates provide the MLS and all content therein “AS IS” and without any warranty, express or implied.Data last updated: 06/02/2022 01:28',
            },
            {
              id: 'footer-text-two',
              type: 'heading',
              className: 'on-vis-footer-text pt-1 pb-3',
              elementType: 'p',
              style: {},
              data: 'IDX information is provided exclusively for consumers’ personal, non-commercial use and that it may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Information deemed reliable but not guaranteed to be accurate. Listing information updated daily.',
            },
          ],
        },
      ],
    },
    {
      id: 'on-vis-footer-second',
      display: true,
      className: 'on-vis-footer-section on-vis-footer-section-second',
      sectionClassName: 'on-vis-footer-section-container-second',
      columns: [
        {
          id: 'col-one',
          className: 'on-vis-footer-column ',
          style: { textAlign: 'left' },
          items: [
            {
              id: 'col-one-heading',
              type: 'heading',
              elementType: 'h1',
              className: 'on-vis-footer-heading',
              style: {},
              data: 'SITE MAP',
            },
            {
              id: 'col-one-menu',
              type: 'menu',
              className: 'on-vis-footer-menu',
              itemClassName: 'mb-0 on-vis-footer-menu-items',
              style: { display: 'grid' },
              items: [
                {
                  id: 'col-one-item-one',
                  data: 'HOME',
                  url: 'https://www.jwteam.com/',
                },
                {
                  id: 'col-one-item-two',
                  data: 'BUY',
                  submenuClassName: '',
                  submenuItemsClassName: 'on-vis-submenu-items',
                  submenuLabelClassName: 'on-vis-submenu-label',
                  submenuItems: [
                    {
                      id: 'sub-one-item-one',
                      submenuItemClassName: '',
                      data: 'ALL LISTINGS',
                      url: 'https://www.jwteam.com/listing',
                    },
                    {
                      id: 'sub-one-item-two',
                      submenuItemClassName: '',
                      data: 'SOLD LISTINGS',
                      url: 'https://www.jwteam.com/sold-listing',
                    },
                    {
                      id: 'sub-one-item-three',
                      submenuItemClassName: '',
                      data: 'FEATURED LISTINGS',
                      url: 'https://www.jwteam.com/featured-listing',
                    },
                  ],
                },
                {
                  id: 'col-one-item-three',
                  data: 'SELL',
                  url: 'http://jwteamsold.com/',
                },
                {
                  id: 'col-one-item-four',
                  data: 'HOME EVALUATION',
                  url: 'https://www.jwteam.com/evaluation',
                },
                {
                  id: 'col-one-item-five',
                  data: 'LISTING PROCESS',
                  url: 'https://www.jwteam.com/sell',
                },
                {
                  id: 'col-one-item-six',
                  data: 'EXCLUSIVE TRADE-IN PROGRAM',
                  url: 'https://trade-in.jwteam.com/',
                },
                {
                  id: 'col-one-item-eight',
                  data: 'EXCLUSIVE CASH OFFER PROGRAM',
                  url: 'https://cashoffer.jwteam.com/',
                },
                {
                  id: 'col-one-item-nine',
                  data: 'NEIGHBORHOODS',
                  submenuClassName: '',
                  submenuItemsClassName: 'on-vis-submenu-items',
                  submenuLabelClassName: 'on-vis-submenu-label',
                  submenuItems: [
                    {
                      id: 'sub-two-item-one',
                      submenuItemClassName: '',
                      data: 'AUSTIN',
                      url: 'https://www.jwteam.com/neighborhoods/153766763/Austin',
                    },
                    {
                      id: 'sub-two-item-two',
                      submenuItemClassName: '',
                      data: 'BUDA',
                      url: 'https://www.jwteam.com/neighborhoods/153766764/Buda',
                    },
                    {
                      id: 'sub-two-item-three',
                      submenuItemClassName: '',
                      data: 'CEDAR PARK',
                      url: 'https://www.jwteam.com/neighborhoods/153766765/Cedar-Park',
                    },
                    {
                      id: 'sub-two-item-four',
                      submenuItemClassName: '',
                      data: 'DRIPPING SPRINGS',
                      url: 'https://www.jwteam.com/neighborhoods/153766766/Dripping-Springs',
                    },
                    {
                      id: 'sub-two-item-five',
                      submenuItemClassName: '',
                      data: 'GEORGETOWN',
                      url: 'https://www.jwteam.com/neighborhoods/153766767/Georgetown',
                    },
                    {
                      id: 'sub-two-item-six',
                      submenuItemClassName: '',
                      data: 'HUTTO',
                      url: 'https://www.jwteam.com/neighborhoods/153766768/Hutto',
                    },
                    {
                      id: 'sub-two-item-seven',
                      submenuItemClassName: '',
                      data: 'KYLE',
                      url: 'https://www.jwteam.com/neighborhoods/153766769/Kyle',
                    },
                    {
                      id: 'sub-two-item-eight',
                      submenuItemClassName: '',
                      data: 'LEANDER',
                      url: 'https://www.jwteam.com/neighborhoods/153766770/Leander',
                    },
                    {
                      id: 'sub-two-item-nine',
                      submenuItemClassName: '',
                      data: 'MANCHACA',
                      url: 'https://www.jwteam.com/neighborhoods/153766771/Manchaca',
                    },
                    {
                      id: 'sub-two-item-ten',
                      submenuItemClassName: '',
                      data: 'PFLUGERVILLE',
                      url: 'https://www.jwteam.com/neighborhoods/153766772/Pflugerville',
                    },
                    {
                      id: 'sub-two-item-eleven',
                      submenuItemClassName: '',
                      data: 'ROUND ROCK',
                      url: 'https://www.jwteam.com/neighborhoods/153766773/Round-Rock',
                    },
                    {
                      id: 'sub-two-item-twelve',
                      submenuItemClassName: '',
                      data: 'SAN MARCOS',
                      url: 'https://www.jwteam.com/neighborhoods/153766774/San-Marcos',
                    },
                  ],
                },
                {
                  id: 'col-one-item-ten',
                  data: 'BLOG',
                  url: 'https://www.jwteam.com/blog',
                },
                {
                  id: 'col-one-item-eleven',
                  data: 'ABOUT',
                  submenuClassName: '',
                  submenuItemsClassName: 'on-vis-submenu-items',
                  submenuLabelClassName: 'on-vis-submenu-label',
                  submenuItems: [
                    {
                      id: 'sub-three-item-one',
                      submenuItemClassName: '',
                      data: 'ABOUT US',
                      url: 'https://www.jwteam.com/about',
                    },
                    {
                      id: 'sub-three-item-two',
                      submenuItemClassName: '',
                      data: 'REVIEWS',
                      url: 'https://www.jwteam.com/reviews',
                    },
                    {
                      id: 'sub-three-item-three',
                      submenuItemClassName: '',
                      data: 'CAREERS',
                      url: 'http://metroaustincareers.com/',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'col-two',
          className: 'on-vis-footer-col-two  ',
          style: {},
          items: [
            {
              id: 'col-two-img',
              type: 'image',
              className: 'on-vis-footer-img',
              alt: 'img',
              url: 'https://cdn.chime.me/image/fs01/sitebuild/20180830/21/h200_original_20669398911370721.png',
              style: {},
            },
            {
              id: 'col-two-heading-one',
              type: 'heading',
              className: 'on-vis-footer-heading-keller  pt-2',
              style: { fontSize: '15px', fontWeight: 'bold', color: 'white' },
              data: 'Keller Williams Realty',
            },
            {
              id: 'col-two-heading-two',
              type: 'heading',
              className: 'on-vis-footer-heading-address',
              style: {},
              data: '1801 S Mopac Expy #100, Austin, TX 78746',
            },
            {
              id: 'col-two-heading-three',
              type: 'heading',
              className: 'on-vis-footer-heading-the  pt-1',
              style: {},
              data: 'The JW Team',
            },
            {
              id: 'col-two-item-content',
              type: 'itemContent',
              className: 'pt-1 pb-1 mb-0 on-vis-footer-itemc',
              style: {},
              items: [
                {
                  content: '512-347-9599',
                  url: 'tel:5123479599',
                  className: 'on-vis-footer-phone',
                },
                // {
                //   content: ' | ',
                //   className: 'on-vis-footer-line',
                // },
                {
                  content: 'transactions@jwteam.com',
                  url: 'mailto:transactions@jwteam.com',
                  className: 'on-vis-footer-email',
                },
              ],
            },
            {
              id: 'col-two-heading-four',
              type: 'heading',
              className: 'on-vis-footer-heading-brokerage pb-1',
              style: {},
              data: 'TREC Information About Brokerage Services',
              url: 'http://static.chimeroi.com/upload/JaymesWilloughby-IABS.pdf',
            },
            {
              id: 'col-two-heading-five',
              type: 'heading',
              className: 'on-vis-footer-heading-brokerage  pt-1 pb-1',
              style: {},
              data: 'TREC Consumer Protection Notice',
              url: 'https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-2.pdf',
            },
            {
              id: 'col-two-socials',
              type: 'socialIcons',
              itemClassName: 'on-vis-footer-socials pt-1',
              style: {},
              items: [
                {
                  id: 'vis-face',
                  url: 'https://www.facebook.com/MetroAustinHomes//',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                },
                {
                  id: 'vis-linked',
                  url: 'https://www.linkedin.com/company-beta/490225/',
                  iconTypeClassName: 'fab',
                  iconClassName: 'linkedin-in',
                },
                {
                  id: 'vis-twitter',
                  url: 'https://twitter.com/WilloughbyTeam',
                  iconTypeClassName: 'fab',
                  iconClassName: 'twitter',
                },
                {
                  id: 'vis-zillow',
                  className: 'on-vis-footer-socials on-footer-vis-zillow',
                  iconUrl: 'https://si-homelight.s3.amazonaws.com/sites/vis/zillow.svg',
                  url: 'https://www.zillow.com/profile/jaymeswilloughby/',
                },
                {
                  id: 'vis-yelp',
                  url: 'https://www.yelp.com/biz/metro-austin-homes-austin',
                  iconTypeClassName: 'fab',
                  iconClassName: 'yelp',
                },
                {
                  id: 'vis-insta',
                  url: 'https://www.instagram.com/metroaustinhomes/',
                  iconTypeClassName: 'fab',
                  iconClassName: 'instagram',
                },
                {
                  id: 'vis-youtube',
                  url: 'https://www.youtube.com/channel/UC1E9N9IVFMk-Ab1Hx-c_1-g',
                  iconTypeClassName: 'fab',
                  iconClassName: 'youtube',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-vis-footer-third',
      display: true,
      className: 'on-vis-footer-section on-vis-footer-section-last',
      sectionClassName: 'on-vis-footer-section-container-third',
      columns: [
        {
          id: 'footer-privacy',
          className: 'align-left on-vis-footer-privacy',
          style: {},
          items: [
            {
              id: 'footer-three-item-content',
              type: 'itemContent',
              className: 'pt-1 mb-0',
              style: {},
              items: [
                {
                  content: 'Made by Chime Technologies, Inc. Copyright 2017. All Rights Reserved.',
                  className: '',
                },
                {
                  content: ' Privacy Policy & TOS',
                  url: 'https://www.jwteam.com/site/privacy-terms',
                  className: 'on-vis-footer-TOS',
                },
              ],
            },
            {
              id: 'footer-text-three',
              type: 'heading',
              className: 'on-vis-footer-text-three',
              elementType: 'p',
              style: {},
              data: 'Disclaimer: The data and valuations are provided as is without warranty or guarantee of any kind, either express or implied, including without limitation, any warranties of merchantability or fitness for a particular purpose. The existence of the subject property and the accuracy of the valuations are estimated based on available data and do not constitute an appraisal of the subject property and should not be relied upon in lieu of underwriting or an appraisal.',
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: { 'tradeIn.hero.agent.showPhoto': 'showAgentPhoto', 'cashOffer.hero.agent.showPhoto': 'showAgentPhoto' },
  siteData: {
    agency: 'JW Team',
    agentName: '',
    agentDescription: 'Top agents in Austin, TX',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/vis/agent.jpg',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/vis/hero-bg.jpg',
    showAgentPhoto: true,
  },
};
